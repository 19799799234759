import React from "react"
import styles from "./NotFoundPage.module.scss"
import classnames from "classnames"
import Button from "src/components/Button/Button"
import Marquee from "react-fast-marquee"
import clsx from "clsx"
const marque = [0, 400, 800]

const CreateTickerLeftShift = shift => {
  return {
    transform: `translateX(-${shift}px)`,
  }
}
const NotFoundPage = () => (
  <div className={classnames("container", styles.main)}>
    <div className={styles.titleText}>
      {marque.map((shift, index) => {
        const isEven = (index + 1) % 2 === 0
        return (
          <Marquee
            gradient={false}
            pauseOnHover={false}
            className={clsx(styles.ticker)}
            speed={20}
            direction={isEven ? "left" : "right"}
            loop={0}
            key={index}
          >
            <div
              style={CreateTickerLeftShift(shift)}
              className={clsx(styles.text, styles.yellow)}
            >
              ERROR 404
            </div>
            <div
              style={CreateTickerLeftShift(shift)}
              className={clsx(styles.text, styles.black)}
            >
              ERROR 404
            </div>
            <div
              style={CreateTickerLeftShift(shift)}
              className={clsx(styles.text, styles.yellow)}
            >
              ERROR 404
            </div>
            <div
              style={CreateTickerLeftShift(shift)}
              className={clsx(styles.text, styles.black)}
            >
              ERROR 404
            </div>
            <div
              style={CreateTickerLeftShift(shift)}
              className={clsx(styles.text, styles.yellow)}
            >
              ERROR 404
            </div>
            <div
              style={CreateTickerLeftShift(shift)}
              className={clsx(styles.text, styles.black)}
            >
              ERROR 404
            </div>
            <div
              style={CreateTickerLeftShift(shift)}
              className={clsx(styles.text, styles.yellow)}
            >
              ERROR 404
            </div>
            <div
              style={CreateTickerLeftShift(shift)}
              className={clsx(styles.text, styles.black)}
            >
              ERROR 404
            </div>
          </Marquee>
        )
      })}
    </div>
    <Button className={styles.button} isLink>
      Go to Main Page
    </Button>
  </div>
)

export default NotFoundPage
