import React from "react"
import BasicLayout from "../components/layout/BasicLayout/BasicLayout"
import SEO from "../components/seo"
import NotFoundPage from "../components/NotFoundPage/NotFoundPage"

const NotFound = () => (
  <BasicLayout withoutFooter containerClassName="p-0">
    <SEO title="404: Not found" />
    <NotFoundPage />
  </BasicLayout>
)

export default NotFound
